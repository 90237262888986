/* lato-100normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Lato Thin '),
    local('Lato-Thin'),
    url(/static/media/lato-latin-100.9ca14b87.woff2) format('woff2'), 
    url(/static/media/lato-latin-100.4dfdd1c0.woff) format('woff'); /* Modern Browsers */
}

/* lato-100italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Lato Thin italic'),
    local('Lato-Thinitalic'),
    url(/static/media/lato-latin-100italic.de3428c2.woff2) format('woff2'), 
    url(/static/media/lato-latin-100italic.83a45512.woff) format('woff'); /* Modern Browsers */
}

/* lato-300normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Lato Light '),
    local('Lato-Light'),
    url(/static/media/lato-latin-300.98d8cf79.woff2) format('woff2'), 
    url(/static/media/lato-latin-300.656963c4.woff) format('woff'); /* Modern Browsers */
}

/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Lato Light italic'),
    local('Lato-Lightitalic'),
    url(/static/media/lato-latin-300italic.a21767e2.woff2) format('woff2'), 
    url(/static/media/lato-latin-300italic.8e90b967.woff) format('woff'); /* Modern Browsers */
}

/* lato-400normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Lato Regular '),
    local('Lato-Regular'),
    url(/static/media/lato-latin-400.b4d2c4c3.woff2) format('woff2'), 
    url(/static/media/lato-latin-400.b8ee546a.woff) format('woff'); /* Modern Browsers */
}

/* lato-400italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Lato Regular italic'),
    local('Lato-Regularitalic'),
    url(/static/media/lato-latin-400italic.117e995c.woff2) format('woff2'), 
    url(/static/media/lato-latin-400italic.e652470b.woff) format('woff'); /* Modern Browsers */
}

/* lato-700normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Lato Bold '),
    local('Lato-Bold'),
    url(/static/media/lato-latin-700.1efbd38a.woff2) format('woff2'), 
    url(/static/media/lato-latin-700.874b8e7b.woff) format('woff'); /* Modern Browsers */
}

/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Lato Bold italic'),
    local('Lato-Bolditalic'),
    url(/static/media/lato-latin-700italic.5ce0990b.woff2) format('woff2'), 
    url(/static/media/lato-latin-700italic.9e9a5744.woff) format('woff'); /* Modern Browsers */
}

/* lato-900normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Lato Black '),
    local('Lato-Black'),
    url(/static/media/lato-latin-900.947e87c5.woff2) format('woff2'), 
    url(/static/media/lato-latin-900.a82ff6ac.woff) format('woff'); /* Modern Browsers */
}

/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Lato Black italic'),
    local('Lato-Blackitalic'),
    url(/static/media/lato-latin-900italic.bd86aaa7.woff2) format('woff2'), 
    url(/static/media/lato-latin-900italic.73d7130e.woff) format('woff'); /* Modern Browsers */
}


